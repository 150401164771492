.aboutContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.aboutImage {
  max-width: 70%;
  height: auto;
  padding-top: 60px;
}

.aboutHeading {
  color: white;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust to truly center the heading */
  z-index: 1;
  width: 100%;
  font-size: 4rem;
  font-family: "Antic Didone", serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 2px 2px 9px rgba(105, 105, 105, 0.5);
  letter-spacing: 4px;
  text-align: center;
  padding: 0 1rem;
  box-sizing: border-box;
}

.aboutText {
margin-top: 50px;
color: rgb(148, 132, 98);
font-size: 16px;
max-width: 70%;
text-align: center;
font-weight: 300;
}

@media (max-width: 768px) {
  .aboutHeading {
    font-size: 6vw;
    top: 40%;
  }

  .aboutText {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .aboutHeading {
    font-size: 8vw;
    top: 45%;
  }

  .aboutText {
    font-size: 0.85rem;
  }
}
