.eachSlideEffect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height:  680px;
  /* max-width:  100%;
  height: auto;
  padding-top: 20px; */
}

.eachSlideEffect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.sliderImage img {
  max-width:  100%;
  height: auto;
  padding-top: 20px;
}
