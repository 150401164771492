.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .socialIcons a, footer .socialIcons a:visited {
  color: rgb(148, 132, 98);
  text-decoration: none;
}

footer .socialIcons a:hover {
  color: rgb(98, 55, 160);
}

.socialIcons {
  justify-content: space-evenly;
  box-sizing: border-box;
  align-items: center;
  list-style-type: none;
  padding-left: 0px;
  font-size: 20px;
}

.footerSocialIcons {
  padding-top: 13px;
}
