/* form {
  margin-top: 20px;
} */

.contactUs {
  padding-top: 50px;
}

.contactUs h1 {
  font-size: 32px;
  padding-top: 20px;
  padding-bottom: 30px;
  color: rgb(148, 132, 98);
}

#contactForm input::placeholder {
  font-weight: 300;
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  padding: 15px 10px;
}

.formInput::placeholder {
font-weight: 300;
}

.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.submitBtn {
  padding: 15px 50px;
  border-radius: 10px;
  margin-bottom: 25px;
 color: white;
 background-color: rgb(148, 132, 98);
 border: none;
 font-weight: 300;
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}
