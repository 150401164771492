.lushhuesContainer {
  display: flex;
  flex-direction:row;
 padding-top: 8px;
 align-items: center;
  column-gap: 50px;
  padding-left: 50px;
}

.navbar {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.navLink {
  color: rgb(148, 132, 98);
}

.navLink:hover {
  color: #6237A0;
}

/* .navLink:visited {
  color: #6237A0 !important;
} */

.searchContainer {
  position: absolute;
  right: 0px;
  padding-left: 10px;
  padding-right: 17px;
  padding-top: 24px;
}

.searchForm {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
}
.searchIcon i {
  position: absolute;
  right: 40px;
  bottom: 10px;

  color: rgb(159, 149, 127);
}

.searchForm input[type="search"]::placeholder {
  color: rgb(148, 132, 98);
  font-size: 13px;
  padding-left: 10px;
  /* font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300; */
}

.formControl {
  border-color: rgb(185, 175, 153);
  border-width: 0.5px;
  border-radius: 5px;
  width: 250px;
  height: 38px;
}
