.listingTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.listingHeading {
  position: absolute;
  left: 50%;
  padding-top: 50px;
  transform: translateX(-50%);
  color: rgb(148, 132, 98);
}

.showAll {
  margin-left: auto;
  padding-right: 0px;
  color: rgb(148, 132, 98);
  font-weight: 300;
}

.showAll:hover {
  color: inherit;
  text-decoration: none;
}
